<template>
  <div class="sovi-magic-login">
    <sovi-stripe />

    <div
      class="fixed inset-0 z-50 grid place-content-center bg-gradient-light dark:bg-gradient-dark"
    >
      <div class="w-screen max-w-96 p-4">
        <div class="mb-8 flex items-center justify-between">
          <sovi-brand-title :logo-size="48" />

          <v-menu offset-y>
            <template #activator="{ props }">
              <v-btn
                class="language-menu"
                size="default"
                color="#eca1c2"
                v-bind="props"
              >
                <template #prepend>
                  <v-icon icon="mdi-web" />
                </template>

                {{ $i18n.locale.toUpperCase() }}
              </v-btn>
            </template>

            <v-list class="text-black/85">
              <v-list-item
                v-for="option in $i18n.availableLocales"
                :key="option"
                class="align-items-center"
                @click="onLocaleChange(option)"
              >
                <v-row align="center" no-gutters>
                  {{ option.toUpperCase() }}
                </v-row>
              </v-list-item>
            </v-list>
          </v-menu>
        </div>

        <template v-if="loading">
          <v-progress-linear
            class="mt-6"
            :color="theme.current.value.dark ? 'primary' : 'tertiary'"
            :bg-color="
              theme.current.value.dark
                ? 'rgba(255,255,255,0.1)'
                : 'rgba(255,255,255,0.5)'
            "
            bg-opacity="1"
            indeterminate
          />

          <p class="text-right text-sm">{{ t('loading') }}...</p>
        </template>

        <template v-else-if="error">
          <p class="d-flex align-center mb-8">
            <v-icon class="mr-2" color="error">mdi-alert</v-icon>
            {{ t('invalidLink') }}
          </p>

          <v-btn color="primary" to="/" block>
            {{ t('requestNewLogin') }}
          </v-btn>
        </template>

        <template v-else-if="link">
          <p class="mb-2 flex items-center font-semibold">
            {{ t('welcome') }} {{ linkParticipantName || user.name }}
          </p>

          <p class="mb-6 flex items-center">
            {{ t('identificationRequired') }}
          </p>

          <sovi-terms
            v-if="!user?.is_identified"
            class="mb-8"
            @change="isTermsAccepted = $event"
          />

          <v-btn :disabled="!canContinue" :href="link" variant="elevated" block>
            {{ t('identify') }}
          </v-btn>
        </template>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { magicLinkRepository } from '@/repositories/magicLinkRepository';
import { useAuthorizationStore } from '@/stores/authorization';
import { useContractStore } from '@/stores/contract';
import { useNetsStore } from '@/stores/nets';
import { storeToRefs } from 'pinia';
import { computed, onBeforeMount, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRoute, useRouter } from 'vue-router';
import { useTheme } from 'vuetify';
import { definePage } from 'vue-router/auto';
import { useLoadingStore } from '@/stores/loading';

definePage({
  name: 'MagicLink',
});

const { endLoading } = useLoadingStore();

const { t } = useI18n();

const route = useRoute();
const router = useRouter();

const theme = useTheme();

const { list: getContracts } = useContractStore();
const { getLink } = useNetsStore();
const { isAuthorized } = useAuthorizationStore();
const { netsPerson, user, linkParticipantName } = storeToRefs(
  useAuthorizationStore(),
);

const error = ref(false);
const loading = ref(true);
const link = ref('');
const isTermsAccepted = ref(false);

const canContinue = computed(() => {
  return !!user.value.is_identified || isTermsAccepted.value;
});

const { locale } = useI18n({ useScope: 'global' });

const onLocaleChange = (newLocale: string) => {
  locale.value = newLocale;
  localStorage.setItem('sovi-locale', newLocale);
};

const validateMagicLink = async () => {
  loading.value = true;

  const token = localStorage.getItem('sovi-token') || '';

  const { data } = await magicLinkRepository.validate({
    link: route.params.hash as string,
    token,
  });

  const { valid, contract, requireAuth, soviToken } = data;

  if (soviToken) {
    localStorage.setItem('sovi-token', soviToken);
  }

  // Redirect to nets if user has not been identified and
  // coming with a magic link directly to a contract
  if (!netsPerson.value && contract && requireAuth) {
    const response = await getLink({ contractId: contract });

    await isAuthorized();

    link.value = response.link;

    loading.value = false;

    endLoading('sovi-fullscreen-loader');

    return;
  }

  await isAuthorized();

  error.value = !valid;

  if (valid) {
    await getContracts();

    if (contract) {
      router.push({
        name: 'Contract',
        params: { contractId: contract },
      });
    } else {
      router.push({ name: 'Contracts' }).catch(() => {});
    }
  }

  if (error.value) {
    endLoading('sovi-fullscreen-loader');

    loading.value = false;
  }
};

onBeforeMount(async () => {
  if (route.params.hash) await validateMagicLink();
});
</script>

<style lang="scss">
.sovi-magic-login {
  height: 100vh;
  display: grid;
  grid-template-columns: 1fr;
  max-width: 1280px;
  place-items: center;

  .v-overlay {
    top: 2px;
  }

  .sovi-stripe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
  }
}
</style>

<i18n lang="json">
{
  "fi": {
    "welcome": "Tervetuloa",
    "loggingIn": "Kirjaudutaan",
    "invalidLink": "Oho! Kirjautumislinkki on väärä tai päässyt vanhentumaan.",
    "requestNewLogin": "Pyydä uusi kirjautumislinkki",
    "identificationRequired": "Asiakirjojen allekirjoittaminen edellyttää vahvaa tunnistautumista.",
    "identify": "Aloita tunnistautuminen",
    "loading": "Ladataan"
  },
  "en": {
    "welcome": "Welcome",
    "loggingIn": "Logging in",
    "invalidLink": "Oh no! The link is not valid or has been expired.",
    "requestNewLogin": "Request a new login link",
    "identificationRequired": "Signing documents requires strong identification.",
    "identify": "Start identification",
    "loading": "Loading"
  }
}
</i18n>
