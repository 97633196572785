<template>
  <div class="mx-2">
    <v-menu
      v-model="showEvents"
      content-class="sovi-menu"
      transition="slide-y-reverse-transition"
      attach
      offset-y
      location="bottom right"
      eager
      :open-delay="3000"
    >
      <template #activator="{ props }">
        <v-badge
          :model-value="newEventCount > 0"
          color="warning"
          :content="newEventCount"
        >
          <v-icon-btn color="" v-bind="props" icon="mdi-email" />
        </v-badge>
      </template>

      <sovi-card>
        <p class="py-4 text-xl font-semibold md:py-6">{{ t('events') }}</p>

        <div
          class="-mx-3 -mb-3 max-h-[60vh] overflow-y-scroll md:-mx-5 md:-mb-5"
        >
          <div
            v-for="event in events"
            :key="event.id"
            v-ripple
            class="grid cursor-pointer gap-0.5 rounded-md px-3 py-2 hover:bg-light-100 md:px-5 dark:hover:bg-dark-600"
            @keyup.enter="redirect(event.hash!)"
            @click="redirect(event.hash!)"
          >
            <p
              class="relative text-base font-semibold"
              :class="{
                'text-tertiary-600 dark:text-tertiary-500':
                  event.acknowledged !== 1,
              }"
            >
              <span
                class="absolute -left-3 top-2 size-2 rounded-full md:-left-4"
                :class="{
                  'bg-tertiary': event.acknowledged === 0,
                  'bg-light-400 dark:bg-dark-500': event.acknowledged !== 0,
                }"
              />

              {{ event.message[$i18n.locale as keyof Message] }}
            </p>

            <p class="break-all">{{ event.name }}</p>

            <p class="opacity-50">
              {{
                formatDate(event.created_at, {
                  locale: $i18n.locale,
                  type: 'full',
                })
              }}
            </p>
          </div>
        </div>
      </sovi-card>
    </v-menu>
  </div>
</template>

<script lang="ts" setup>
import { ref, computed, watch, onMounted } from 'vue';
import { useRouter } from 'vue-router';
import { formatDate } from '@/utils';
import { useI18n } from 'vue-i18n';
import { useParticipantStore } from '@/stores/participant';
import { useContractStore } from '@/stores/contract';
import { storeToRefs } from 'pinia';
import { useEventStore } from '@/stores/event';
import type { Message } from '@/types/event';

const { t } = useI18n();

const router = useRouter();

const showEvents = ref(false);

const loading = ref(true);

const { events } = storeToRefs(useEventStore());

// const { getEvents } = useEventStore();

const newEventCount = computed(
  () => events.value.filter((event) => event.acknowledged === 0).length,
);

const { listParticipants } = useParticipantStore();

const { getContract } = useContractStore();

const updateContractData = (contractId: string) => {
  listParticipants(contractId);

  getContract({ contractId });
};

watch(
  events,
  (items) => {
    if (!items) return;

    loading.value = items.length === 0;

    setTimeout(() => {
      loading.value = false;
    }, 5000);
  },
  { immediate: true },
);

const redirect = (contractId: string) => {
  showEvents.value = false;

  events.value = events.value.map((event) => {
    if (event.hash === contractId) {
      return { ...event, acknowledged: 1 };
    }

    return event;
  });

  updateContractData(contractId);

  router.push({
    name: 'Contract',
    params: { contractId },
  });
};

onMounted(() => {
  // stop loader after 5 seconds even if no events have been received
  setTimeout(() => {
    loading.value = false;
  }, 5000);
});
</script>

<style lang="scss">
.pulse {
  .v-badge__badge {
    animation: 4s ease-in-out 2s infinite pulse;
  }
}

.event-list {
  border-bottom-left-radius: 0 !important;
  border-bottom-right-radius: 0 !important;

  > div {
    max-height: 80vh;
  }
}

@keyframes pulse {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(255, 82, 82, 0.7);
  }

  35% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(255, 82, 82, 0);
  }

  50% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(255, 82, 82, 0);
  }
}
</style>

<i18n lang="json">
{
  "fi": {
    "events": "Tapahtumat",
    "noEvents": "Ei tapahtumia",
    "loadMore": "Lataa lisää"
  },
  "en": {
    "events": "Events",
    "noEvents": "No events",
    "loadMore": "Load more"
  }
}
</i18n>
