<template>
  <v-menu
    close-on-content-click
    transition="slide-y-reverse-transition"
    content-class="sovi-menu"
    attach
    location="bottom right"
    offset-y
  >
    <template #activator="{ props }">
      <v-btn
        v-if="!display.mobile.value && user?.name"
        v-bind="props"
        class="ml-2 !text-black"
        size="default"
        rounded
        color="tertiary"
      >
        <template #prepend>
          <v-icon>mdi-account</v-icon>
        </template>

        {{ user.name }}
      </v-btn>

      <v-btn
        v-else
        v-bind="props"
        color="tertiary"
        class="!size-9 !min-w-9"
        variant="text"
        rounded
      >
        <v-icon>{{ user.name ? 'mdi-account' : 'mdi-cogs' }}</v-icon>
      </v-btn>
    </template>

    <v-card>
      <v-list class="m-0 bg-transparent p-0" dense>
        <div v-if="!!user.name" class="px-4 pt-2">
          <div class="my-2 flex flex-wrap items-center gap-2 text-sm">
            <v-icon color="tertiary">mdi-account</v-icon>

            <div class="mb-0 grid">
              <p class="font-semibold">
                {{ user.name }}
              </p>

              <p>
                {{ user.email }}
              </p>
            </div>
          </div>
        </div>

        <p class="-mb-3 ms-4 pt-3 text-sm">{{ t('theme') }}</p>

        <div class="flex bg-transparent px-2 pb-0 pt-4 shadow-none">
          <v-btn
            v-ripple="{ class: 'text-primary' }"
            class="grow !rounded-e-none"
            size="default"
            :color="theme === 'light' ? 'primary' : ''"
            :variant="theme === 'light' ? 'flat' : 'tonal'"
            @click.stop="theme = 'light'"
          >
            <template #prepend>
              <v-icon>mdi-weather-sunny</v-icon>
            </template>

            {{ t('themes.light') }}
          </v-btn>

          <v-btn
            v-ripple="{ class: 'text-primary' }"
            class="!m-0 grow !rounded-s-none"
            size="default"
            :color="theme === 'dark' ? 'primary' : ''"
            :variant="theme === 'dark' ? 'flat' : 'tonal'"
            @click.stop="theme = 'dark'"
          >
            <template #prepend>
              <v-icon>mdi-weather-night</v-icon>
            </template>

            {{ t('themes.dark') }}
          </v-btn>
        </div>

        <p class="-mb-3 ms-4 mt-3 text-sm">{{ t('language') }}</p>

        <div class="flex bg-transparent px-2 py-4 shadow-none">
          <v-btn
            v-for="(localeItem, index) in $i18n.availableLocales"
            :key="localeItem"
            v-ripple="{ class: 'text-primary' }"
            :color="$i18n.locale === localeItem ? 'primary' : ''"
            :variant="$i18n.locale === localeItem ? 'flat' : 'tonal'"
            class="grow"
            :class="index === 0 ? '!rounded-e-none' : '!m-0 !rounded-s-none'"
            size="default"
            @click.stop="onLocaleChange(localeItem)"
          >
            <template #prepend>
              <fi v-if="localeItem === 'fi'" class="w-4" />

              <en v-if="localeItem === 'en'" class="w-4" />
            </template>
            {{ t(localeItem) }}
          </v-btn>
        </div>

        <div
          v-if="isActionsVisible && showActions"
          class="grid gap-4 px-2 pt-2"
        >
          <v-btn
            color="tertiary"
            size="default"
            block
            variant="outlined"
            :to="{ name: 'TemplateManagement' }"
          >
            {{ t('templateManagement') }}
          </v-btn>

          <v-btn
            color="tertiary"
            size="default"
            block
            variant="outlined"
            :to="{ name: 'BillingHistory' }"
          >
            {{ t('billingHistory') }}
          </v-btn>

          <v-btn
            v-if="+user.is_company === 0 && !user.companies_checked"
            color="tertiary"
            size="default"
            block
            variant="outlined"
            @click="showBusinessFeaturesDialog = true"
          >
            {{ t('companyRequest') }}
          </v-btn>

          <v-btn
            v-if="+(user.admin || 0) === 1"
            color="tertiary"
            size="default"
            block
            variant="outlined"
            :to="{ name: 'AdminView' }"
          >
            {{ t('adminView') }}
          </v-btn>

          <v-btn
            v-if="isCompany && showActions"
            color="tertiary"
            size="default"
            block
            variant="outlined"
            :to="{ name: 'CompanySettings' }"
          >
            {{ t('companySettings') }}
          </v-btn>
        </div>

        <v-card-actions v-if="user.email && isActionsVisible" class="mt-4">
          <v-btn
            :disabled="isLoading('logout')"
            :loading="isLoading('logout')"
            color="tertiary"
            block
            variant="elevated"
            @click.stop="onLogout"
          >
            {{ t('logout') }}
          </v-btn>
        </v-card-actions>
      </v-list>
    </v-card>
  </v-menu>

  <sovi-company-request-dialog
    :display="showBusinessFeaturesDialog"
    @cancel="showBusinessFeaturesDialog = false"
  />
</template>

<script setup lang="ts">
import { storeToRefs } from 'pinia';
import { useI18n } from 'vue-i18n';
import { useDisplay, useTheme } from 'vuetify';
import { useLoadingStore } from '@/stores/loading';
import { useAuthorizationStore } from '@/stores/authorization';
import { computed, ref, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';

import fi from '@/assets/images/flags/fi.svg';
import en from '@/assets/images/flags/en.svg';
import { useAppStore } from '@/stores/app';

const route = useRoute();

const isActionsVisible = computed(() => {
  return !['Verify'].includes(route.name as string);
});

const { t } = useI18n();

const { locale } = useI18n({ useScope: 'global' });

const display = useDisplay();

const vuetifyTheme = useTheme();

const router = useRouter();

const { isLoading } = useLoadingStore();

const { logout } = useAuthorizationStore();

const { user } = storeToRefs(useAuthorizationStore());

const { showBusinessFeaturesDialog } = storeToRefs(useAppStore());

const showActions = computed(() => {
  return !route.meta.hideHeaderActions;
});

const isCompany = computed(() => {
  return +(user.value?.is_company || 0) === 1;
});

const theme = ref(
  localStorage.getItem('sovi-theme') ||
    (window.matchMedia('(prefers-color-scheme: dark)')?.matches
      ? 'dark'
      : 'light'),
);

watch(
  theme,
  (value) => {
    vuetifyTheme.global.name.value = value;
    localStorage.setItem('sovi-theme', value);
  },
  { immediate: true },
);

const onLocaleChange = (newLocale: string) => {
  locale.value = newLocale;
  localStorage.setItem('sovi-locale', newLocale);
};

const onLogout = async () => {
  await logout();

  router.push({ name: 'Login' });
};
</script>

<i18n lang="json">
{
  "fi": {
    "logout": "Kirjaudu ulos",
    "billingHistory": "Laskutushistoria",
    "companySettings": "Yritysyhteydet",
    "theme": "Teema",
    "language": "Kieli",
    "fi": "Suomi",
    "en": "Englanti",
    "adminView": "Ylläpito",
    "companyRequest": "Pyydä yritystoimintoja",
    "themes": {
      "light": "Vaalea",
      "dark": "Tumma"
    },
    "templateManagement": "Asiakirjamallit"
  },
  "en": {
    "logout": "Logout",
    "billingHistory": "Billing history",
    "companySettings": "Company connections",
    "theme": "Theme",
    "language": "Language",
    "fi": "Finnish",
    "en": "English",
    "adminView": "Admin view",
    "companyRequest": "Request company features",
    "themes": {
      "light": "Light",
      "dark": "Dark"
    },
    "templateManagement": "Contract templates"
  }
}
</i18n>
