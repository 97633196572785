<template>
  <div id="top" ref="homeRef" class="sovi-home text-black/70">
    <v-menu offset-y>
      <template #activator="{ props }">
        <v-btn
          class="language-menu"
          size="default"
          color="#eca1c2"
          v-bind="props"
        >
          <template #prepend>
            <v-icon icon="mdi-web" />
          </template>

          {{ $i18n.locale.toUpperCase() }}
        </v-btn>
      </template>

      <v-list>
        <v-list-item
          v-for="option in $i18n.availableLocales"
          :key="option"
          class="align-items-center"
          @click="onLocaleChange(option)"
        >
          <v-row align="center" no-gutters>
            {{ option.toUpperCase() }}
          </v-row>
        </v-list-item>
      </v-list>
    </v-menu>

    <div
      class="sovi-home__header md:!grid-cols-[55vw_1fr] md:!grid-rows-none md:!content-stretch"
    >
      <div class="sovi-home__image relative">
        <img
          src="/images/home.webp"
          class="sm:!object-[10%_50%] md:!h-svh md:!w-[60lvw] md:!object-[20%_50%]"
          alt="Sovi home header image"
          data-prl-y=""
          data-prl-scale="1.8"
          data-prl-overflow-y=""
        />
      </div>

      <sovi-home-login @resize="onResize" />

      <div class="absolute bottom-12 right-1/2 z-10 m-[-24px] md:m-[-28px]">
        <v-icon-btn
          variant="elevated"
          color="tertiary"
          icon="mdi-arrow-down"
          class="relative before:absolute before:inset-1 before:animate-ping before:rounded-full before:bg-tertiary-500 before:animate-duration-[2000ms] md:!size-12 before:md:inset-2"
          @click="onScrollToContent()"
        />
      </div>
    </div>

    <sovi-home-card
      ref="contentRef"
      background="/images/desk.jpg"
      src="/images/sovi-web-1.jpg"
      :title="t('agreeEasily')"
      fixed
    >
      <strong>{{ t('electronicSignatureService') }}</strong>

      <p>
        {{ t('contentText1') }}
      </p>

      <p>
        {{ t('contentText2') }}
      </p>

      <template #button>
        <v-btn
          class="text-none justify-self-start"
          color="primary"
          @click="onReadMore()"
        >
          {{ t('readMore') }}
          <template #append>
            <v-icon class="mt-1">mdi-arrow-right</v-icon>
          </template>
        </v-btn>
      </template>
    </sovi-home-card>

    <sovi-home-card
      src="/images/sovi-web-2.jpg"
      :title="t('securelyTitle')"
      class="bg-gradient-light"
      flipped
    >
      <strong>{{ t('securelySubtitle') }}</strong>

      <p>
        {{ t('securelyContent') }}
      </p>

      <template #button>
        <v-btn
          class="text-none justify-self-start"
          color="primary"
          @click="onReadMore()"
        >
          {{ t('readMore') }}
          <template #append>
            <v-icon class="mt-1">mdi-arrow-right</v-icon>
          </template>
        </v-btn>
      </template>
    </sovi-home-card>

    <div
      class="sovi-home__content !min-h-[600px] !gap-[clamp(16px,2vw,24px)] bg-primary-500 md:!grid-cols-[auto_minmax(15em,620px)_minmax(25em,820px)_auto]"
    >
      <div class="sovi-home__content--text md:!col-[2]">
        <h2 class="home-title alt font-bold">{{ t('mobile') }}</h2>

        <p class="font-bold">
          {{ t('fasterSubtitle') }}
        </p>

        <ul class="ms-6 list-disc">
          <li>{{ t('bullet0') }}</li>

          <li>{{ t('bullet1') }}</li>

          <li>{{ t('bullet2') }}</li>

          <li>{{ t('bullet3') }}</li>

          <li>{{ t('bullet4') }}</li>

          <li>{{ t('bullet5') }}</li>

          <li>{{ t('bullet6') }}</li>

          <li>{{ t('bullet7') }}</li>
        </ul>
      </div>

      <div
        class="sovi-home__content--image md:!col-[3] md:!mt-16 md:!min-h-[780px]"
        style="background-image: url('/images/sovi_mobile.webp')"
      />
    </div>

    <sovi-home-pricing />

    <sovi-home-contact />

    <sovi-home-card
      ref="readMoreRef"
      background="/images/desk.jpg"
      src="/images/sovi-web-3.jpg"
      :title="t('agreeBetter')"
      fixed
    >
      <strong>{{ t('enhance') }}</strong>

      <p>{{ t('betterExperienceText') }}</p>

      <ul class="ms-6 list-disc">
        <li>{{ t('bullet15') }}</li>

        <li>{{ t('bullet16') }}</li>

        <li>{{ t('bullet18') }}</li>
      </ul>

      <strong>{{ t('betterExperience') }}</strong>

      <ul class="ms-6 list-disc">
        <li>{{ t('bullet8') }}</li>

        <li>{{ t('bullet9') }}</li>

        <li>{{ t('bullet11') }}</li>

        <li>{{ t('bullet12') }}</li>
      </ul>
    </sovi-home-card>
  </div>
</template>

<script lang="ts" setup>
import { ref, onMounted } from 'vue';
import { useTheme } from 'vuetify';
import { loadRecaptcha } from '@/plugins/recaptcha';
import { useAuthorizationStore } from '@/stores/authorization';
import { useI18n } from 'vue-i18n';
import type SoviHomeCard from '@/components/home/SoviHomeCard.vue';
import { definePage } from 'vue-router/auto';

definePage({
  name: 'Login',
  meta: {
    hero: false,
    restricted: false,
    maxContainer: true,
  },
});

const homeRef = ref<HTMLDivElement | null>(null);

const contentRef = ref<InstanceType<typeof SoviHomeCard> | null>(null);

const readMoreRef = ref<InstanceType<typeof SoviHomeCard> | null>(null);

const theme = useTheme();

const { locale } = useI18n({ useScope: 'global' });
const { t } = useI18n();

const { isAuthorized } = useAuthorizationStore();

const onResize = (height: string) => {
  homeRef.value?.style.setProperty('--sovi-home-form-height', height);
};

const onLocaleChange = (newLocale: string) => {
  locale.value = newLocale;
  localStorage.setItem('sovi-locale', newLocale);
};

const onReadMore = () => {
  (readMoreRef.value?.$el as HTMLDivElement).scrollIntoView({
    behavior: 'smooth',
  });
};

const onScrollToContent = () => {
  (contentRef.value?.$el as HTMLDivElement).scrollIntoView({
    behavior: 'smooth',
  });
};

onMounted(() => {
  loadRecaptcha();

  theme.global.name.value = 'light';

  isAuthorized();
});
</script>

<style lang="scss">
body {
  height: 100svh;
  overflow: auto;
  scroll-behavior: smooth;
}

.grecaptcha-badge {
  visibility: hidden;
}

.sovi-home {
  background-color: #fff;
  font-size: clamp(18px, 2vw, 22px);

  .col-span-2 {
    grid-column: span 2;
  }

  .alt-bg {
    background-color: #eed7e2;
  }

  .alt-bg-dark {
    background-color: #eca1c2;
  }

  .alt-text {
    color: #eca1c2;
  }

  .justify-self-start {
    justify-self: start;
  }

  .home-title {
    --title-color: rgb(var(--v-theme-primary));

    &.alt {
      --title-color: #eca1c2;
    }

    &::first-letter {
      padding-bottom: 2px;
      box-shadow: inset 0 calc(-1 * clamp(4px, 0.5vw, 6px)) 0 0
        var(--title-color);
    }
  }

  .language-menu {
    position: absolute;
    top: 16px;
    right: 16px;
    z-index: 2;
    box-shadow:
      rgba(0, 0, 0, 0.15) 0px 15px 25px,
      rgba(0, 0, 0, 0.05) 0px 5px 10px;
  }

  p {
    margin: 0;
  }

  &__header {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr auto;
    height: 100svh;
    position: relative;
    overflow: hidden;
    align-content: end;
  }

  &__image {
    position: relative;

    &::after {
      background: radial-gradient(transparent, rgba(0, 0, 0, 0.4));
      content: '';
      position: absolute;
      inset: 0;
    }

    img {
      margin-top: 0 !important;
      object-position: 30% 50%;
      width: 100lvw;
      height: calc(100svh - var(--sovi-home-form-height));
      object-fit: cover;
    }
  }

  &__login {
    display: grid;
    background-color: #fff;
    position: relative;
    justify-content: stretch;
    align-content: center;

    &::before,
    &::after {
      content: '';
      position: absolute;
      inset: -7vh;
      background-color: #fff;
      rotate: 4deg;
    }

    // &::before {
    //   opacity: 0.7;
    //   backdrop-filter: blur(5px);
    // }

    &::after {
      background-color: #fff;
      rotate: -3deg;
      box-shadow:
        rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
        rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
    }

    // @include mq($from: md) {
    //   justify-content: left;
    //   place-content: center;

    //   &::before,
    //   &::after {
    //     inset: -164px -64px -300px;
    //   }

    //   &::before {
    //     rotate: calc(4deg + calc(4deg * var(--headerScale)));
    //   }
    // }

    form {
      position: relative;
      // padding: 16px;
      // top: -48px;

      // @include mq($from: md) {
      //   left: -32px;
      //   top: 0;
      //   width: clamp(400px, 30vw, 600px);
      //   justify-self: center;
      //   align-self: center;
      // }
    }

    &-form {
      display: grid;
      gap: clamp(16px, 3vw, 32px);
      position: relative;
      z-index: 1;

      h1 {
        font-size: clamp(24px, 3vw, 32px);
      }

      .sovi-brand-title {
        white-space: nowrap;
      }

      // @include mq($from: md) {
      //   translate: 0px calc(0.5vh * var(--headerOpacity));
      // }
    }
  }

  &__content {
    $this: &;

    // background-color: map-get($material-light, 'background');
    // color: map-get($sovi-text-color, 'light');
    display: grid;
    gap: 0;
    font-size: clamp(18px, 2vw, 22px);
    font-weight: 500;
    margin: 0 auto;
    grid-template-columns: 1fr;

    &.card {
      position: relative;
      grid-template-columns: 1fr;
      padding: clamp(16px, 5vw, 92px);

      #{$this}--wrapper {
        background-color: #fff;
        display: grid;
        border-radius: 16px;
        // box-shadow: $sovi-box-shadow;
        z-index: 1;
        margin: 0 auto;
        overflow: hidden;

        // @include mq($from: md) {
        //   min-height: 600px;
        //   max-width: 1280px;
        //   gap: clamp(16px, 2vw, 24px);
        //   grid-template-columns: 1fr 1fr;
        // }
      }

      #{$this}--image,
      #{$this}--text {
        grid-column: auto;
      }

      #{$this}--image {
        background-size: cover;
        background-position: center;
        min-height: 400px;
        height: 100%;
        grid-row: 1;

        // @include mq($from: md) {
        //   min-height: auto;
        //   margin-top: 0;
        //   grid-column: 2;
        // }
      }

      &.flip {
        #{$this}--image {
          // @include mq($from: md) {
          //   grid-column: 1;
          // }
        }
      }
    }

    &.fixed {
      background-size: cover;
      background-position: center;

      // @include mq($from: sm) {
      //   background-attachment: fixed;
      // }

      &::after {
        background-color: rgba(255, 255, 255, 0.75);
        backdrop-filter: blur(6px);
        content: '';
        position: absolute;
        inset: 0;
      }
    }

    // @include mq($from: md) {
    //   min-height: 600px;
    //   gap: clamp(16px, 2vw, 24px);
    //   grid-template-columns: auto minmax(15em, 620px) minmax(25em, 820px) auto;
    // }

    &--text {
      display: grid;
      gap: clamp(16px, 2vw, 24px);
      padding: clamp(16px, 5vw, 64px) clamp(16px, 5vw, 16px);
      place-content: center;
      z-index: 1;

      &:not(.second) {
        // @include mq($from: md) {
        //   grid-column: 2;
        // }
      }

      h2 {
        font-size: clamp(24px, 3vw, 42px);
      }
    }

    &--image {
      background-size: contain;
      background-position: center bottom;
      min-height: 400px;

      // @include mq($from: md) {
      //   min-height: 780px;
      //   margin-top: 72px;
      //   grid-column: 3;
      // }
    }
  }

  .dark-bg {
    background-color: #000;
  }

  &__footer {
    padding: 8px;

    .wrapper {
      border-radius: 16px;
      padding: clamp(8px, 2vw, 24px) clamp(16px, 2vw, 32px);

      > div {
        max-width: 1440px;
        margin: 0 auto;
      }
    }
  }
}

html {
  overflow: hidden;
}
</style>

<i18n lang="json">
{
  "fi": {
    "mobile": "Sovi nopeammin",
    "fasterSubtitle": "Säästä ajassa ja tarvikkeissa. Unohda mapit ja aikaa haaskaava paperinpyörittely",
    "linkCreated": "Kirjautumislinkki on lähetetty antamaasi sähköpostiosoitteeseen.",
    "salesPitch": "Työsopimukset, vuokrasopimukset tai vaikka autokaupat - sovi mistä tahansa sähköisin allekirjoituksin! Kirjoita sähköpostiosoitteesi oheiseen kenttään niin lähetämme Sinulle kirjautumislinkin. Linkillä pääset Sovi.fi -palveluun, jossa sopimuksesi säilyvät tallessa.",
    "slogan": "Sähköistä sopimista",
    "name": "Nimi",
    "nameRequired": "Anna nimesi, ole hyvä",
    "message": "Viesti",
    "messageRequired": "Kirjoita viestisi, ole hyvä",
    "submitMessage": "Lähetä viesti",
    "biggerOrganization": "Isompi organisaatio?",
    "agreeEasily": "Sovi helpommin",
    "electronicSignatureService": "Helppokäyttöinen ja turvallinen sähköinen allekirjoituspalvelu",
    "contentText1": "Sovi on sähköinen PDF-asiakirjojen allekirjoituspalvelu, jossa sopimuksesi säilyvät järjestyksessä. Palvelussa tehdyt allekirjoitukset ovat sitovia ja allekirjoitettujen asiakirjojen aitous on sähköisesti todennettavissa.",
    "contentText2": "Myynti- ja urakkasopimukset, asiakassopimukset, vuokrasopimukset, työsopimukset tai valtakirjat - Sovi mistä tahansa turvallisesti ja sitovasti.",
    "bullet0": "Kaikki toiminnot myös mobiilissa",
    "bullet1": "Palvelu heti käytössäsi",
    "bullet2": "Ei sitovaa kuukausisopimusta",
    "bullet3": "Myös yksityishenkilöiden väliseen sopimiseen",
    "bullet4": "Luo kansioita ja alakansioita",
    "bullet5": "Raahaa ja pudota sopimukset omiin kansioihin",
    "bullet6": "Turvallinen ja sitova sopimistapa",
    "bullet7": "Reaaliaikainen allekirjoitusprosessin edistymisen seuranta",
    "securelyTitle": "Sovi turvallisemmin",
    "securelySubtitle": "Tiedä kenen kanssa asioit - varmistu allekirjoittajan henkilöllisyydestä vahvan tunnistautumisen avulla",
    "securelyContent": "Turvallisuus ja luotettavuus ovat Sovi.fi-palvelun tärkeimmät prioriteetit. Asiakirjasi säilyvät turvassa ajantasaisten suojaustekniikoiden avulla. Lisäksi palvelun tietoliikenne on suojattu vahvalla salauksella, joka estää väärien tahojen pääsyn tietoihisi. Palvelun käyttö edellyttää vahvaa tunnistautumista pankkitunnuksilla tai mobiilivarmenteella Suomen valtion valvontaviranomaisen, Traficomin hallinnoiman Finnish Trust Networkin kautta.",
    "agreeBetter": "Sovi paremmin",
    "enhance": "Tehosta toimintaasi",
    "betterExperience": "Parempi asiakaskokemus",
    "betterExperienceText": "Ei paperisia asiakirjoja, tulostamista tai postittamista, kaikki hoituu automaattisesti. Halutessasi voit seurata reaaliajassa allekirjoitusprosessin etenemistä.",
    "bullet8": "Automaattinen arkisto itsellesi ja asiakkaallesi",
    "bullet9": "Automaattiset muistutusviestit",
    "bullet10": "Säästää aikaa ja rahaa kaikilta sopimusosapuolilta",
    "bullet11": "Allekirjoitettuun asiakirjaan lisätään yksilöllinen tunniste, josta selviää, kuka asiakirjan on allekirjoittanut ja milloin",
    "bullet12": "Allekirjoitetun asiakirjan mukana tulevan linkin avulla kuka tahansa voi todentaa asiakirjan aitouden",
    "bullet13": "Käytössäsi heti, koska tahansa ja missä tahansa",
    "bullet14": "Sovi uudet asiakkuudet käden käänteessä",
    "bullet15": "Säästä ajassa ja tarvikkeissa, ja unohda paperinpyörittely",
    "bullet16": "Osapuolten vahva tunnistautuminen - tiedä kenen kanssa asioit",
    "bullet17": "Sovi.fi luo juridisesti sitovan, sähköisen allekirjoituksen",
    "bullet18": "Suojattu, salasanaton kirjautuminen",
    "readMore": "Lue lisää",
    "businessID": "Y-tunnus",
    "terms": "Käyttöehdot",
    "dataProtectionStatement": "Tietosuojaseloste",
    "paymentTerms": "Toimitus- ja maksuehdot"
  },
  "en": {
    "mobile": "Agree faster",
    "fasterSubtitle": "Save in time and supplies. Forget manual filing and time wasting paper pushing",
    "salesPitch": "Employment contracts, leases or even car dealerships - settle averything with electronic signatures! Enter your email address to the attached field and we will send you a login link. The link will take you to the Sovi.fi service, where your contracts will be saved.",
    "slogan": "Electronic contract",
    "name": "Name",
    "nameRequired": "Input your name please",
    "message": "Message",
    "messageRequired": "Input a message please",
    "submitMessage": "Submit message",
    "biggerOrganization": "Service personalization",
    "agreeEasily": "Agree easier",
    "electronicSignatureService": "Safe and easy-to-use e-signature platform",
    "contentText1": "Sovi is a PDF document signing service, which keeps your contracts in order. Sovi e-signatures are legally binding and all signatures can be electronically verified.",
    "contentText2": "Sales and client agreements, rental contracts, employment contracts or power of attorneys and proxies - agree on anything securely and legally binding.",
    "bullet0": "All services also in your mobile device",
    "bullet1": "Service at your disposal immediately",
    "bullet2": "No binding monthly subscription",
    "bullet3": "Also for consumer use",
    "bullet4": "Create folders and subfolders",
    "bullet5": "Drag and drop documents to respective folders",
    "bullet6": "Safe and binding way to reach an agreement",
    "bullet7": "Real-time signature process notifications",
    "securelyTitle": "Agree safer",
    "securelySubtitle": "Know who you are dealing with - identity verified through strong FTN verification",
    "securelyContent": "Security and reliability are the top priorities of Sovi.fi service. Your documents are safely stored with up-to-date security technologies. In addition, the Sovi.fi network traffic is protected with strong encryption, which prevents wrong parties from accessing your information. Using Sovi.fi requires strong identification using e-banking credentials or a mobile certificate via the Finnish Trust Network which is managed by Traficom, the Finnish state supervisory authority.",
    "agreeBetter": "Agree better",
    "enhance": "Improve your service",
    "betterExperience": "Better customer experience",
    "betterExperienceText": "No paper documents, printing or postage fees, everything is being handled automatically. Possibility to follow signature process in real time.",
    "bullet8": "Automated archive for you and your customer",
    "bullet9": "Automated customer emails and reminders",
    "bullet10": "Saves time and money for all parties",
    "bullet11": "All signed documents are incorporated with a unique identifier which details who signed the document and when",
    "bullet12": "All signed documents are provided with a unique link for anyone to verify document authenticity",
    "bullet13": "Service at your disposal immediately, where ever, whenever",
    "bullet14": "Turn leads into new customers in minutes",
    "bullet15": "Save in time and supplies, forget paper pushing",
    "bullet16": "Strong verification of identity - know who you are dealing with",
    "bullet17": "Sovi.fi creates a legally binding e-signature",
    "bullet18": "Secured password-free login",
    "readMore": "Read more",
    "businessID": "Business ID",
    "terms": "Terms of use",
    "dataProtectionStatement": "Data protection statement",
    "paymentTerms": "Terms of payment and delivery"
  }
}
</i18n>
